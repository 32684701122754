import React from 'react';
import { graphql } from 'gatsby';
import Layout from './../components/Layout';
import CategoryItem from '../components/CategoryItem';


const renderImg = (data) =>{
  if(data){
   return( <img alt="" className="d-block position-relative" src={data.url}></img>);
  } else {
    return(<div className="static-height-header"></div>);
  }
}

const renderContentPost = (data) => {
  if(data && data.html){
    return(
      <div className="content-from-server w-100" dangerouslySetInnerHTML={{ __html: data.html }}></div>
    );
  }
}

const renderTimeToRead = (data) => {
  if(data && data.timeToRead){
    return(
      <div className="white-color">{data.timeToRead} min czytania</div>
    );
  }
}

const PostTemplate = ({ pageContext: {slug}, data: {post} }) => {
const postContentChild = post.contentNode.childMarkdownRemark;

  return (
   <Layout>
     <div className="title-mask mb-8 position-relative">
       <div className="img-wrapper">
        {renderImg(post.thumbnail)}
        <div className="img-mask position-absolut w-100"></div>
       </div>
       
        <div className="main-wrapper position-absolut w-100 header-section">
          <div className="d-flex justify-content-center">
          <CategoryItem name="test"></CategoryItem>
          <CategoryItem name="Ubezpieczenia"></CategoryItem>
          <CategoryItem name="Szkody"></CategoryItem>
          </div>
          <div className="text-align-center mt-4">
            <h1 className="white-color">{post.title}</h1>
            <div className="d-flex mt-8 flex-column">
              <div className="d-flex justify-content-center mb-2 flex-row">
                <div className="d-flex solid-line w-10 white-bg"></div>
              </div>
              {renderTimeToRead(postContentChild)}
            </div>
            
          </div>
        </div>
     </div>
     <div className="main-wrapper">
        {renderContentPost(postContentChild)}
 
     </div>
   </Layout>
  );
};

export default PostTemplate;

export const query = graphql`
query($slug: String!) {
  post: datoCmsPost(slug: {eq: $slug}){
    id, 
    slug,
    content,
    contentNode {
      childMarkdownRemark {
        html
      }
    },
    thumbnail {
      url
    },
    title,
    video {
      url
      title
      provider
      providerUid
      thumbnailUrl
      width
      height
    }
  }
}
`;
