import React from "react";

class CategoryItem extends React.Component {
    constructor(props){
        super(props);
    }


    render() {
        return (
        <div className="category-item green-bg d-flex-inline mr-1">{this.props.name}</div>
        );
    }
}
export default CategoryItem;